<template>
    <div class="my-all-course">
        <van-sticky>
            <div class="my-all-course__search">
                <van-search
                    class="my-search"
                    v-model="value"
                    shape="round"
                    placeholder="请输入马场名称"
                    @search="onSearch"
                    >
                </van-search>
            </div>
            <van-tabs v-model="active" class="my-tabs" @change="changeCourse">
                <van-tab title="未上课"></van-tab>
                <van-tab title="已上课"></van-tab>
                <van-tab title="超时未上课"></van-tab>
            </van-tabs>
        </van-sticky>
        <van-pull-refresh class="my-pull-refresh"  success-text="刷新成功" v-model="isLoading" @refresh="onRefresh">
            <van-list
                v-model="loading"
                :finished="finished"
                @load="onLoad1"
                class="my-list"
                >
                <div class="my-course-list">
                    <my-course v-for="item in list" :key="item.id" :item="item" :complete="item.curriculumTimeStatus !== 2"
                        @goAppraise="goAppraise(item)"
                        @goToCourse="goToCourse(item)"
                        @goComputed="goComputed(item)">
                        <div slot="type" v-if="$help.storeTemp.get('personType') === 0">
                            <div class="info coach">
                                <div class="label">教练</div>
                                <div class="value">{{item.coachName}}</div>
                            </div>
                            <div class="info level">
                                <div class="label">级别</div>
                                <div class="value">{{item.coachLevelName}}</div>
                            </div>
                        </div>
                        <div slot="type" v-if="$help.storeTemp.get('personType') === 1">
                            <div class="info coach" @click="goMember(item.memberId, item.id)"> 
                                <div class="label">姓名</div>
                                <div class="value">{{item.memberName}} <van-icon name="arrow" /></div>
                            </div>
                            <div class="info level">
                                <div class="label">昵称</div>
                                <div class="value">{{item.memberName}}</div>
                            </div>
                        </div>
                    </my-course>
                </div>
                <no-more v-show="showNoMore && !loading"/>
            </van-list>
            <no-data v-show="showData"/>
        </van-pull-refresh>

        <van-dialog v-model="showAppraise" title="评价教练" show-cancel-button @confirm="changeRecode" @cancel="onCancelRecode" :before-close="beforeClose">
            <van-cell-group>
                <van-field center clearable type="textarea" v-model="appraise" placeholder="请输入评价教练内容"/>
                <van-field class="my-cell" center label="能力分值(满分100)" required type="number" v-model="abilityScore" placeholder="请输入教练能力分值"/>
                <van-field class="my-cell" center label="态度分值(满分100)" required type="number" v-model="attitudeScore" placeholder="请输入教练态度分值"/>
            </van-cell-group>
        </van-dialog>

        <van-dialog v-model="showCourse" title="上课提示" show-cancel-button cancelButtonText="已超时" confirmButtonText="去上课" @confirm="diaConfirm" @cancel="diaCancel">
            <div class="dialogContent">确认去上课或课程超时</div>
            <van-icon name="cross" @click="showCourse=false" />
        </van-dialog>

    </div>
</template>

<script>
import mixinPage from '@/utils/mixinPage'
export default {
    mixins: [ mixinPage ],
    data() {
        return { 
            api: '',
            value: '',
            active: 0,
            model: {},
            appraise: '',
            attitudeScore: '',
            abilityScore: '',
            flag: false,
            showAppraise: false,
            selectItem: {},
            selectCourse: {},
            showCourse: false,
            orders: [{column: "billDate", asc: false}]
        }
    },
    methods: {
        init() {
            this.initList()
        },
        beforeClose(action, done) {
            if (action === 'confirm') {
                if(!this.flag){
                    done(false)
                }else{
                    setTimeout(done, 100);
                }
            } else{
                done()
            }
        },
        changeRecode(){
            if(this.appraise === ''){
                this.$help.error("评语不能为空")
                this.flag = false
                return
            }
            if(this.attitudeScore === ''){
                this.$help.error("态度分值不能为空")
                this.flag = false
                return
            }
            if(this.abilityScore === ''){
                this.$help.error("能力分值不能为空")
                this.flag = false
                return
            }
            this.flag = true
            let form = {
                id: this.selectItem.id,
                abilityComment: this.appraise,
                attitudeScore: this.attitudeScore,
                abilityScore: this.abilityScore
            }
            this.$help.loading('评价中...')
            this.$api.post('member/bill/curriculumOrderAndTime/curriculumOrder-evaluation', form).then(res=>{
                if(res.data){
                    this.$help.success('评价成功')
                    this.init()
                }else{
                    this.$help.error(res.msg)
                }
                this.appraise = ''
                this.attitudeScore = ''
                this.abilityScore = ''
            })
            .finally(this.$help.loaded)
        },
        onCancelRecode(){
            this.appraise = ''
            this.attitudeScore = ''
            this.abilityScore = ''
        },
        goToCourse(data) {
            if(this.$help.storeTemp.get('personType') === 1 && data.curriculumTimeStatus === 2){
                this.selectCourse = data
                this.showCourse = true
                return
            }
        },
        goMember(memberId, id) {
            this.$router.push({path: '/coach/memberdetail', query: {
                memberId,
                id
            }})
        },
        diaConfirm() {
            //console.log('去上课')
            this.$api.post('coach/bill/curriculumTime/curriculumTime-status', {
                id: this.selectCourse.id,
                curriculumTimeStatus: 3
            }).then(res=>{
                console.log(res)
                if(res.data) {
                    this.$help.success('处理成功!')
                    this.init()
                }else{
                    this.$help.error(res.msg)
                }
            })
        },
        diaCancel() {
            //console.log('已超时')
            this.$api.post('coach/bill/curriculumTime/curriculumTime-status', {
                id: this.selectCourse.id,
                curriculumTimeStatus: 5
            }).then(res=>{
                console.log(res)
                if(res.data) {
                    this.$help.success('处理成功!')
                    this.init()
                }else{
                    this.$help.error(res.msg)
                }
            })
        },
        goAppraise(data) {
            console.log(data)
            this.selectItem = data
            this.showAppraise = true
        },
        //完成上课
        goComputed(data) {
            this.$help.confirm({
                title: '提示',
                message: '确定完成上课吗',
            })
            .then(() => {
                this.$api.post('coach/bill/curriculumTime/curriculumTime-status', {
                    id: data.id,
                    curriculumTimeStatus: 4
                }).then(res=>{
                    //console.log(res)
                    if(res.data) {
                        this.$help.success('处理成功!')
                        this.init()
                    }else{
                        this.$help.error(res.msg)
                    }
                })
            })
            .catch(() => {})
        },
        onSearch() {
            this.$set(this.model, 'corpNameLike', this.value)
            this.init()
        },
        changeCourse(data) {
            //console.log(data)
            this.model = {}
            if(data === 0) {
                this.model.curriculumTimeStatus = 2
            }else if(data === 1){
                this.model.fieldFilters = {
                    "groupOp": "and",
                    "rules": [
                        {
                            "dataList": [3, 4, 6],
                            "field": "curriculumTimeStatus",
                            "op": "in",
                        },
                    ]
                }
                // this.model.curriculumTimeStatusStart = 3
                // this.model.curriculumTimeStatusEnd = 6
            }else {
                this.model.curriculumTimeStatus = 5
            }
            this.init()
        }
    },
    mounted() {
        this.model.curriculumTimeStatus = 2
        if(this.$help.storeTemp.get('personType') === 0) {
            //会员
            this.api = 'member/bill/curriculumOrderAndTime/curriculumTime-findCurriculumTimePage'
        }
        if(this.$help.storeTemp.get('personType') === 1) {
            //教练
            this.api = 'coach/bill/curriculumTime/curriculumTime-list'
        }
        this.init()
    }
    
}
</script>

<style lang="scss">
    @import '~@/assets/css/var';
    .my-all-course{
        &__search {
            // margin: 40px 0 20px;
            padding: 0 20px 0 8px;
            background-color: #fff;
            .van-search__action{
                color: $main;
            }
        }
        .my-tabs{
            .van-tabs__line{
                background-color: $main;
                width: 80px;
            }
            
        }
        .my-pull-refresh{
            .my-list{
                padding: 0 25px;
            }
            .my-course-list{
                .My-Course {
                    padding: 10px 0;
                    .course {
                        margin-right: unset;
                    }
                }
            }
        }
        .van-dialog{
            .dialogContent{
                text-align: center;
                padding: 20px 0;
            }
            .van-icon{
                position: absolute;
                top: 12px;
                right: 12px;
                color: $red;
                font-size: 20px;
            }
            .my-cell{
                .van-field__label{
                    width: 120px;
                }
            }
        }
    }
</style>